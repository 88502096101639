import * as React from "react"
import { FC } from "react"
import { Box, Container, Grid, Heading, Text } from "theme-ui"

import { Orphans } from "components/Orphans"

export interface IPropsHeroPost {
  date: string
  description?: string
  timeToRead: number
  title: string
}

export const HeroPost: FC<IPropsHeroPost> = ({
  date,
  description,
  timeToRead,
  title,
}) => {
  const prettyDate = new Date(date).toDateString()

  const shortHero = title.length < 45
  return (
    <Container
      as="header"
      sx={{
        bg: "backgroundPrimary",
        py: [15],
        minHeight: "10vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid variant="base" sx={{ alignItems: "center", textAlign: "center" }}>
        <Heading
          as="h1"
          sx={{
            color: "textWhite",
            gridColumn: ["1/13", null, null, "2/12"],
            letterSpacing: ["tight5", null, null, null, "tight6"],
            wordSpacing: "0.1em",
            lineHeight: [1.25, null, null, 1.15],
            fontSize: shortHero
              ? [11, 15, 14, 15, 17, 20]
              : [9, 13, 12, 13, 15, 18],
            mb: [3, 5, null, null, 6],
            "::selection": {
              bg: "backgroundSelectionDark",
            },
          }}
        >
          <Orphans>{title}</Orphans>
        </Heading>
        {description && (
          <Text
            sx={{
              color: "textWhite",
              fontSize: [6, 7, 6, null, 8, 9],
              mb: [3, 4, null, null, 5, null],
              gridColumn: ["2/12", null, null, "3/11"],
              letterSpacing: "tight4",
              lineHeight: 1.25,
              "::selection": {
                bg: "backgroundSelectionDark",
              },
            }}
          >
            <Orphans>{description}</Orphans>
          </Text>
        )}
        <Box
          sx={{
            display: "flex",
            gridColumn: "1/13",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <Text
            sx={{
              fontSize: [3, null, null, null, null, 4],
              color: "textWhite",
              letterSpacing: "tight5",
              "::selection": {
                bg: "backgroundSelectionDark",
              },
            }}
          >
            {timeToRead} min read
          </Text>
          <Text
            sx={{
              fontSize: [2, null, null, null, null, 3],
              letterSpacing: "tight5",
              color: "textWhite",
              "::selection": {
                bg: "backgroundSelectionDark",
              },
            }}
          >
            <Box as="span" sx={{ mx: [2, null, null, 3] }}>
              —
            </Box>
            {prettyDate}
          </Text>
        </Box>
      </Grid>
    </Container>
  )
}
