/** @jsx jsx */
import * as React from "react"
import { FC } from "react"
import { Link } from "gatsby"
import { jsx, Box, Divider, Heading, SxStyleProp, Text } from "theme-ui"

import { Orphans } from "components/Orphans"
import { TagList } from "components/TagList"

export interface IPropsRelatedPost {
  author?: string
  className?: string
  date: string
  description?: string
  slug: string
  tags?: string[]
  title: string
  timeToRead?: number
}

const dateAuthorStyle: SxStyleProp = {
  fontSize: [2, null, null, null, 3],
  color: "textMuted",
}

export const RelatedPost: FC<IPropsRelatedPost> = ({
  author,
  date,
  tags,
  title,
  description,
  slug,
  timeToRead,
}) => {
  return (
    <Link to={slug} sx={{ textDecoration: "none", color: "text" }}>
      <Divider
        sx={{
          mt: 0,
          height: 4,
          border: 0,
          gridColumn: "span 8",
          width: "100%",
          ["--thickness"]: "6px",
          position: "relative",
          color: "text",
          bg: "backgroundTertiary",
          backgroundImage:
            "linear-gradient(to bottom, currentColor 100%, transparent 0%)",
          backgroundSize: "0% var(--thickness)",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          backgroundPositionY: "bottom",
          transition: "background-size 0.25s var(--animation-bezier)",
          mb: 7,

          "a:hover &": {
            backgroundSize: "100% var(--thickness)",
            backgroundPositionX: "left",
            backgroundPositionY: "bottom",
          },
        }}
      />
      <Heading
        as="h5"
        sx={{
          fontSize: [5, null, null, 6, 7, 8],
          mb: [3, 4, null, null, 5],
          lineHeight: [1.4, 1.3, null, null, 1.25],
        }}
      >
        <Orphans>{title}</Orphans>
      </Heading>
      {description && (
        <Text
          sx={{
            mb: [3, null, null, null, 5],
            fontSize: [3, null, null, 4, null, 5],
          }}
        >
          {description}
        </Text>
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "baseline",
        }}
      >
        <Text
          sx={{
            ...dateAuthorStyle,
          }}
        >
          {new Date(date).toDateString()}
        </Text>
        <Text
          sx={{
            mb: [2, 3, 2],
            ...dateAuthorStyle,
          }}
        >
          <Text
            as="span"
            sx={{
              mx: 2,
            }}
          >
            —
          </Text>
          by {author}
        </Text>
        <Text
          sx={{
            fontSize: [1, null, null, null, 2],
            color: "textMutedMore",
            ml: ["auto", 0],
            width: [null, "100%"],
            mb: [3, 4],
          }}
        >
          {timeToRead} min read
        </Text>
      </Box>
      {tags && (
        <Text
          sx={{
            mb: [4, 5, 4],
          }}
        >
          <TagList tags={tags} />
        </Text>
      )}
    </Link>
  )
}
