import * as React from "react"
import { FC } from "react"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image";
import { Box, Text } from "theme-ui"
import { animated, useTrail, config } from "react-spring"

import { Avatar } from "components/Avatar"

export interface IPropsAuthor {
  avatar?: {
    childImageSharp: GatsbyImage
  }
  author: string
  jobTitle: string
}

export const Author: FC<IPropsAuthor> = ({ avatar, author, jobTitle }) => {
  const [ref, inView, entry] = useInView({ threshold: 0.6 })
  const belowViewport = !inView && entry && entry.intersectionRect.top > 0

  const AvatarWrapper = () => {
    return (
      <Box
        sx={{
          width: [128, null, null, null, 160],
          mt: [128 * -0.5, null, null, null, 160 * -0.5],
          p: [3, null, null, null, 4],
          mx: "auto",
        }}
      >
        <Avatar src={avatar} alt={author} />
      </Box>
    )
  }

  const Name = () => (
    <Text
      sx={{
        fontSize: [4, 5, 6],
        mb: [1, null, null, null, null, 2],
        lineHeight: 1.25,
        letterSpacing: "tight5",
      }}
    >
      {author}
    </Text>
  )

  const Title = () => (
    <Text
      sx={{
        fontSize: [1, 2],
        lineHeight: 1.25,
        color: "textMuted",
      }}
    >
      {jobTitle}
    </Text>
  )

  const items = [...(avatar ? [<AvatarWrapper />] : []), <Name />, <Title />]

  const trail = useTrail(items.length, {
    config: config.stiff,
    y: belowViewport ? 100 : 0,
  })

  return (
    <Box ref={ref} sx={{ textAlign: "center" }}>
      {trail.map(({ y }, index) => {
        return (
          <Box
            sx={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" }}
            key={index}
          >
            <animated.div
              style={{
                transform: y.to(
                  (value: number) => `translate3d(0,${value}%,0)`
                ),
              }}
            >
              {items[index]}
            </animated.div>
          </Box>
        )
      })}
    </Box>
  )
}
