import * as React from "react"
import { FC } from "react"
import { AspectRatio } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image";

export interface IPropsAvatar {
  alt: string
  src: {
    childImageSharp: GatsbyImageFluidProps
  }
}

export const Avatar: FC<IPropsAvatar> = ({ src, alt }) => {
  return (
    <AspectRatio
      ratio={1}
      sx={{
        borderRadius: "50%",
        overflow: "hidden",
        img: {
          "::selection": {
            bg: "backgroundSelectionDark",
          },
        },
      }}
    >
      <GatsbyImage image={src.childImageSharp.gatsbyImageData} alt={alt} />
    </AspectRatio>
  );
}
